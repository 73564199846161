@import 'import';
.Countdown {
  $this: &;

  cursor: default;
  display: flex;
  flex-direction: row;
  gap: rem(4);

  &__digit {
    display: flex;
    flex-direction: row;
    gap: rem(2);
    align-items: center;

    #{$this}._default & {
      flex-direction: column;
    }

    #{$this}._unlabeled & {
      gap: rem(4);
    }
  }

  &__value {
    font-size: rem(13);
    line-height: rem(20);

    #{$this}._default & {
      font-size: rem(28);
      line-height: rem(28);
      text-align: center;
    }
  }

  &__label {
    font-size: rem(13);
    line-height: rem(20);

    #{$this}._default & {
      font-size: rem(9);
      line-height: rem(11);
      text-align: center;
    }
  }
}
